import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { Card, CardContent, TextField } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const commands = ["domain", "ip", "a", "aaaa", "cname", "mx", "ns", "txt"];

// const commands: CommandParam[] = [{
//     regex: /$[a]/, params: [],
// }]

interface CommandInputUIProps {
  runCommand: (cmd: string, param: string) => void;
}

export const CommandInputUI: React.FC<CommandInputUIProps> = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [commandStr, setCommandStr] = React.useState("");

  const handleCommandChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const command = event.target.value;
    const s = command.split(" ", 1);
    const i = commands.indexOf(s[0]);

    if (i > -1 && command.indexOf(" ") > -1) {
      setSelectedIndex(i);
      if (s.length > 1) {
        setCommandStr(s[1]);
      } else {
        setCommandStr("");
      }
    } else {
      setCommandStr(command);
    }
  };

  const handleChange = (cmd: string) => {
    console.log(cmd);
  };

  const handleClick = (event: React.FormEvent) => {
    props.runCommand(commands[selectedIndex], commandStr);
    setCommandStr("");
    event.preventDefault();
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <form onSubmit={handleClick}>
          <Grid container>
            <Grid item>
              <Button
                className={classes.buttonLeft}
                ref={anchorRef}
                variant={"contained"}
                color={"primary"}
                onClick={handleToggle}
              >
                {commands[selectedIndex]}
              </Button>
            </Grid>
            <Grid item xs>
              <TextField
                required={true}
                className={classes.input}
                fullWidth={true}
                placeholder={""}
                variant={"outlined"}
                value={commandStr}
                size={"small"}
                onChange={handleCommandChange}
              />
            </Grid>
            <Grid item>
              <Button
                type={"submit"}
                className={classes.buttonRight}
                variant="contained"
                color={"primary"}
                // onClick={handleClick}
              >
                Run
              </Button>
            </Grid>
            {/*</ButtonGroup>*/}
          </Grid>
        </form>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {commands.map((option, index) => (
                      <MenuItem
                        key={option}
                        // disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
  },
  content: {
    paddingBottom: "16px",
  },
  buttonLeft: {
    borderRadius: "4px 0 0 4px",
    padding: "7.5px 16px",
  },
  buttonRight: {
    borderRadius: "0 4px 4px 0",
    padding: "7.5px 16px",
  },
  input: {
    "& > .MuiOutlinedInput-root": {
      borderRadius: "0",
    },
  },
});
