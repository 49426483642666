import React from "react";
import { StyleSheet } from "react-native";
import { ThisIPApp } from "./components";
import { ApolloProvider } from "@apollo/react-hooks";
import { client } from "./components/apollo";
import { AppStateProvider } from "./components/state";

export default function App() {
  return (
    <ApolloProvider client={client}>
      <AppStateProvider>
        {/*<Router>*/}
        <ThisIPApp />
        {/*</Router>*/}
      </AppStateProvider>
    </ApolloProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
