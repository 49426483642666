import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface Props {
  command: string;
}

export const CommandCardUI: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.cardHeader} variant={"h4"}>
          {props.command}
        </Typography>
        {props.children}
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles({
  card: {
    marginBottom: "1.5rem",
  },
  cardHeader: {
    marginBottom: "8px",
    // borderBottomColor: "#e0e0e0",
    // borderBottomWidth: ".1rem",
    // borderBottomStyle: "solid",
  },
});
