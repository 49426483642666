import * as React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { CommandCardUI } from "./command-card-ui";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

interface Props {
  command: string;
  count?: number;
  errors?: string[];
}

export const CommandCardLoadingUI: React.FC<Props> = (props) => {
  function skeleton(i: number) {
    return (
      <Grid key={i} container>
        <Grid item xs style={{ marginRight: "1rem" }}>
          <Typography>
            <Skeleton />
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <Skeleton />
          </Typography>
        </Grid>
      </Grid>
    );
  }

  function error(i: number, error: string) {
    return <Alert severity={"error"}>{error}</Alert>;
  }

  function skeletons() {
    if (props.count) {
      let s: React.ReactElement[] = [];
      for (let i = 0; i < props.count; i++) {
        s = [...s, skeleton(i)];
      }
      return s;
    }
    if (props.errors) {
      let s: React.ReactElement[] = [];
      for (let i = 0; i < props.errors.length; i++) {
        s = [...s, error(i, props.errors[i])];
      }
      return s;
    }
  }

  return <CommandCardUI command={props.command} children={skeletons()} />;
};
