// import * as React from "react";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import * as Cookies from "js-cookie";

// const host = process.env.SITE_URL || 'localhost:8000';
const host =
  process.env.NODE_ENV === "production"
    ? // ? "thisip-net.appspot.com"
      "www.thisip.net"
    : "localhost:8080";
// const host =
//   process.env.NODE_ENV === "production" ? "localhost:8080" : "localhost:8080";
const ssl = process.env.NODE_ENV === "production" ? "s" : "";
// const ssl = process.env.NODE_ENV === "production" ? "" : "";
const token = Cookies.get("csrftoken");

export const siteUrl = "http" + ssl + "://" + host + "/";

const httpLink = createHttpLink({
  uri: "http" + ssl + "://" + host + "/graphql", // todo: env?
  // credentials: 'include',
  // headers: {
  //     "X-CSRFToken": token
  // }
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
});
