import * as React from "react";
import { useDomainQuery } from "./commands.gen";
import { CommandCardUI } from "./command-card/command-card-ui";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { CommandCardLoadingUI } from "./command-card/command-card-loading-ui";

interface Props {
  domain: string;
}

export const ACommandUI: React.FC<Props> = (props) => {
  const { data, loading, error } = useDomainQuery({
    variables: { domain: props.domain },
  });
  if (loading) {
    return <CommandCardLoadingUI command={props.domain} count={2} />;
  }
  if (error) {
    return <div>ERROR: {error}</div>;
  }
  return (
    <CommandCardUI command={props.domain}>
      {data!.domain.a.length > 0 && (
        <Grid container>
          <Grid item xs>
            <Typography>A</Typography>
          </Grid>
          <Grid item xs>
            {data!.domain.a.map((ip: string) => (
              <Typography key={ip}>{ip}</Typography>
            ))}
          </Grid>
        </Grid>
      )}
      {data!.domain.aaaa.length > 0 && (
        <Grid container>
          <Grid item xs>
            <Typography>AAAA</Typography>
          </Grid>
          <Grid item xs>
            {data!.domain.aaaa.map((ip) => (
              <Typography key={ip}>{ip}</Typography>
            ))}
          </Grid>
        </Grid>
      )}
      {data!.domain.cname.length > 0 && (
        <Grid container>
          <Grid item xs>
            <Typography>CNAME</Typography>
          </Grid>
          <Grid item xs>
            {data!.domain.cname.map((ip) => (
              <Typography key={ip}>{ip}</Typography>
            ))}
          </Grid>
        </Grid>
      )}
      {data!.domain.mx.length > 0 && (
        <Grid container>
          <Grid item xs>
            <Typography>MX</Typography>
          </Grid>
          <Grid item xs>
            {data!.domain.mx.map((ip) => (
              <Typography key={ip}>{ip}</Typography>
            ))}
          </Grid>
        </Grid>
      )}
      {data!.domain.ns.length > 0 && (
        <Grid container>
          <Grid item xs>
            <Typography>NS</Typography>
          </Grid>
          <Grid item xs>
            {data!.domain.ns.map((ip) => (
              <Typography key={ip}>{ip}</Typography>
            ))}
          </Grid>
        </Grid>
      )}
      {data!.domain.txt.length > 0 && (
        <Grid container>
          <Grid item xs>
            <Typography>TXT</Typography>
          </Grid>
          <Grid item xs>
            {data!.domain.txt.map((ip) => (
              <Typography key={ip}>{ip}</Typography>
            ))}
          </Grid>
        </Grid>
      )}
    </CommandCardUI>
  );
};
