import * as Types from '../graphql/types.gen';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type DomainQueryVariables = {
  domain: Types.Scalars['String'];
};


export type DomainQuery = (
  { __typename?: 'RootQuery' }
  & { domain: (
    { __typename?: 'DomainType' }
    & Pick<Types.DomainType, 'a' | 'aaaa' | 'cname' | 'mx' | 'ns' | 'txt'>
  ) }
);

export type AQueryVariables = {
  domain: Types.Scalars['String'];
};


export type AQuery = (
  { __typename?: 'RootQuery' }
  & { domain: (
    { __typename?: 'DomainType' }
    & Pick<Types.DomainType, 'a'>
  ) }
);

export type AaaaQueryVariables = {
  domain: Types.Scalars['String'];
};


export type AaaaQuery = (
  { __typename?: 'RootQuery' }
  & { domain: (
    { __typename?: 'DomainType' }
    & Pick<Types.DomainType, 'aaaa'>
  ) }
);

export type CnameQueryVariables = {
  domain: Types.Scalars['String'];
};


export type CnameQuery = (
  { __typename?: 'RootQuery' }
  & { domain: (
    { __typename?: 'DomainType' }
    & Pick<Types.DomainType, 'cname'>
  ) }
);

export type MxQueryVariables = {
  domain: Types.Scalars['String'];
};


export type MxQuery = (
  { __typename?: 'RootQuery' }
  & { domain: (
    { __typename?: 'DomainType' }
    & Pick<Types.DomainType, 'mx'>
  ) }
);

export type NsQueryVariables = {
  domain: Types.Scalars['String'];
};


export type NsQuery = (
  { __typename?: 'RootQuery' }
  & { domain: (
    { __typename?: 'DomainType' }
    & Pick<Types.DomainType, 'ns'>
  ) }
);

export type TxtQueryVariables = {
  domain: Types.Scalars['String'];
};


export type TxtQuery = (
  { __typename?: 'RootQuery' }
  & { domain: (
    { __typename?: 'DomainType' }
    & Pick<Types.DomainType, 'txt'>
  ) }
);

export type IpQueryVariables = {
  ip: Types.Scalars['String'];
};


export type IpQuery = (
  { __typename?: 'RootQuery' }
  & { ip: Array<(
    { __typename?: 'IPItemType' }
    & Pick<Types.IpItemType, 'id' | 'label' | 'value'>
  )> }
);

export type ThisIpQueryVariables = {};


export type ThisIpQuery = (
  { __typename?: 'RootQuery' }
  & Pick<Types.RootQuery, 'thisip'>
);


export const DomainDocument = gql`
    query Domain($domain: String!) {
  domain(domain: $domain) {
    a
    aaaa
    cname
    mx
    ns
    txt
  }
}
    `;

/**
 * __useDomainQuery__
 *
 * To run a query within a React component, call `useDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useDomainQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DomainQuery, DomainQueryVariables>) {
        return ApolloReactHooks.useQuery<DomainQuery, DomainQueryVariables>(DomainDocument, baseOptions);
      }
export function useDomainLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DomainQuery, DomainQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DomainQuery, DomainQueryVariables>(DomainDocument, baseOptions);
        }
export type DomainQueryHookResult = ReturnType<typeof useDomainQuery>;
export type DomainLazyQueryHookResult = ReturnType<typeof useDomainLazyQuery>;
export type DomainQueryResult = ApolloReactCommon.QueryResult<DomainQuery, DomainQueryVariables>;
export const ADocument = gql`
    query A($domain: String!) {
  domain(domain: $domain) {
    a
  }
}
    `;

/**
 * __useAQuery__
 *
 * To run a query within a React component, call `useAQuery` and pass it any options that fit your needs.
 * When your component renders, `useAQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useAQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AQuery, AQueryVariables>) {
        return ApolloReactHooks.useQuery<AQuery, AQueryVariables>(ADocument, baseOptions);
      }
export function useALazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AQuery, AQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AQuery, AQueryVariables>(ADocument, baseOptions);
        }
export type AQueryHookResult = ReturnType<typeof useAQuery>;
export type ALazyQueryHookResult = ReturnType<typeof useALazyQuery>;
export type AQueryResult = ApolloReactCommon.QueryResult<AQuery, AQueryVariables>;
export const AaaaDocument = gql`
    query AAAA($domain: String!) {
  domain(domain: $domain) {
    aaaa
  }
}
    `;

/**
 * __useAaaaQuery__
 *
 * To run a query within a React component, call `useAaaaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAaaaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAaaaQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useAaaaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AaaaQuery, AaaaQueryVariables>) {
        return ApolloReactHooks.useQuery<AaaaQuery, AaaaQueryVariables>(AaaaDocument, baseOptions);
      }
export function useAaaaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AaaaQuery, AaaaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AaaaQuery, AaaaQueryVariables>(AaaaDocument, baseOptions);
        }
export type AaaaQueryHookResult = ReturnType<typeof useAaaaQuery>;
export type AaaaLazyQueryHookResult = ReturnType<typeof useAaaaLazyQuery>;
export type AaaaQueryResult = ApolloReactCommon.QueryResult<AaaaQuery, AaaaQueryVariables>;
export const CnameDocument = gql`
    query CNAME($domain: String!) {
  domain(domain: $domain) {
    cname
  }
}
    `;

/**
 * __useCnameQuery__
 *
 * To run a query within a React component, call `useCnameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCnameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCnameQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useCnameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CnameQuery, CnameQueryVariables>) {
        return ApolloReactHooks.useQuery<CnameQuery, CnameQueryVariables>(CnameDocument, baseOptions);
      }
export function useCnameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CnameQuery, CnameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CnameQuery, CnameQueryVariables>(CnameDocument, baseOptions);
        }
export type CnameQueryHookResult = ReturnType<typeof useCnameQuery>;
export type CnameLazyQueryHookResult = ReturnType<typeof useCnameLazyQuery>;
export type CnameQueryResult = ApolloReactCommon.QueryResult<CnameQuery, CnameQueryVariables>;
export const MxDocument = gql`
    query MX($domain: String!) {
  domain(domain: $domain) {
    mx
  }
}
    `;

/**
 * __useMxQuery__
 *
 * To run a query within a React component, call `useMxQuery` and pass it any options that fit your needs.
 * When your component renders, `useMxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMxQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useMxQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MxQuery, MxQueryVariables>) {
        return ApolloReactHooks.useQuery<MxQuery, MxQueryVariables>(MxDocument, baseOptions);
      }
export function useMxLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MxQuery, MxQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MxQuery, MxQueryVariables>(MxDocument, baseOptions);
        }
export type MxQueryHookResult = ReturnType<typeof useMxQuery>;
export type MxLazyQueryHookResult = ReturnType<typeof useMxLazyQuery>;
export type MxQueryResult = ApolloReactCommon.QueryResult<MxQuery, MxQueryVariables>;
export const NsDocument = gql`
    query NS($domain: String!) {
  domain(domain: $domain) {
    ns
  }
}
    `;

/**
 * __useNsQuery__
 *
 * To run a query within a React component, call `useNsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNsQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useNsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NsQuery, NsQueryVariables>) {
        return ApolloReactHooks.useQuery<NsQuery, NsQueryVariables>(NsDocument, baseOptions);
      }
export function useNsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NsQuery, NsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NsQuery, NsQueryVariables>(NsDocument, baseOptions);
        }
export type NsQueryHookResult = ReturnType<typeof useNsQuery>;
export type NsLazyQueryHookResult = ReturnType<typeof useNsLazyQuery>;
export type NsQueryResult = ApolloReactCommon.QueryResult<NsQuery, NsQueryVariables>;
export const TxtDocument = gql`
    query TXT($domain: String!) {
  domain(domain: $domain) {
    txt
  }
}
    `;

/**
 * __useTxtQuery__
 *
 * To run a query within a React component, call `useTxtQuery` and pass it any options that fit your needs.
 * When your component renders, `useTxtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTxtQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useTxtQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TxtQuery, TxtQueryVariables>) {
        return ApolloReactHooks.useQuery<TxtQuery, TxtQueryVariables>(TxtDocument, baseOptions);
      }
export function useTxtLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TxtQuery, TxtQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TxtQuery, TxtQueryVariables>(TxtDocument, baseOptions);
        }
export type TxtQueryHookResult = ReturnType<typeof useTxtQuery>;
export type TxtLazyQueryHookResult = ReturnType<typeof useTxtLazyQuery>;
export type TxtQueryResult = ApolloReactCommon.QueryResult<TxtQuery, TxtQueryVariables>;
export const IpDocument = gql`
    query IP($ip: String!) {
  ip(ip: $ip) {
    id
    label
    value
  }
}
    `;

/**
 * __useIpQuery__
 *
 * To run a query within a React component, call `useIpQuery` and pass it any options that fit your needs.
 * When your component renders, `useIpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIpQuery({
 *   variables: {
 *      ip: // value for 'ip'
 *   },
 * });
 */
export function useIpQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IpQuery, IpQueryVariables>) {
        return ApolloReactHooks.useQuery<IpQuery, IpQueryVariables>(IpDocument, baseOptions);
      }
export function useIpLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IpQuery, IpQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IpQuery, IpQueryVariables>(IpDocument, baseOptions);
        }
export type IpQueryHookResult = ReturnType<typeof useIpQuery>;
export type IpLazyQueryHookResult = ReturnType<typeof useIpLazyQuery>;
export type IpQueryResult = ApolloReactCommon.QueryResult<IpQuery, IpQueryVariables>;
export const ThisIpDocument = gql`
    query ThisIP {
  thisip
}
    `;

/**
 * __useThisIpQuery__
 *
 * To run a query within a React component, call `useThisIpQuery` and pass it any options that fit your needs.
 * When your component renders, `useThisIpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThisIpQuery({
 *   variables: {
 *   },
 * });
 */
export function useThisIpQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ThisIpQuery, ThisIpQueryVariables>) {
        return ApolloReactHooks.useQuery<ThisIpQuery, ThisIpQueryVariables>(ThisIpDocument, baseOptions);
      }
export function useThisIpLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ThisIpQuery, ThisIpQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ThisIpQuery, ThisIpQueryVariables>(ThisIpDocument, baseOptions);
        }
export type ThisIpQueryHookResult = ReturnType<typeof useThisIpQuery>;
export type ThisIpLazyQueryHookResult = ReturnType<typeof useThisIpLazyQuery>;
export type ThisIpQueryResult = ApolloReactCommon.QueryResult<ThisIpQuery, ThisIpQueryVariables>;