import * as React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { CssBaseline } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { client } from "./apollo/index";
import { Router, Route } from "./router/router";
import { CommandInputUI } from "./command-input/command-input-ui";
import { ACommandUI } from "./commands/a-ui";
import { IPCommandUI } from "./commands/ip-ui";
import { DomainCommandUI } from "./commands/domain-ui";
import { AAAACommandUI } from "./commands/aaaa-ui";
import { CNAMECommandUI } from "./commands/cname-ui";
import { MXCommandUI } from "./commands/mx-ui";
import { NSCommandUI } from "./commands/ns-ui";
import { TXTCommandUI } from "./commands/txt-ui";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { useThisIpQuery } from "./commands/commands.gen";

function getID() {
  return new Date().getTime();
}

interface Props {}

export const ThisIPApp: React.FC<Props> = (props) => {
  const [cmdHistory, setCmdHistory] = React.useState<React.ReactElement[]>([]);

  const addHistory = (elem: React.ReactElement) => {
    setCmdHistory([elem, ...cmdHistory]);
  };

  const runCommand = (cmd: string, param: string) => {
    switch (cmd) {
      case "domain": {
        addHistory(<DomainCommandUI key={getID()} domain={param} />);
        break;
      }
      case "ip": {
        addHistory(<IPCommandUI key={getID()} ip={param} />);
        break;
      }
      case "a": {
        addHistory(<ACommandUI key={getID()} domain={param} />);
        break;
      }
      case "aaaa": {
        addHistory(<AAAACommandUI key={getID()} domain={param} />);
        break;
      }
      case "cname": {
        addHistory(<CNAMECommandUI key={getID()} domain={param} />);
        break;
      }
      case "mx": {
        addHistory(<MXCommandUI key={getID()} domain={param} />);
        break;
      }
      case "ns": {
        addHistory(<NSCommandUI key={getID()} domain={param} />);
        break;
      }
      case "txt": {
        addHistory(<TXTCommandUI key={getID()} domain={param} />);
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  };

  const { data, loading, error } = useThisIpQuery();

  return (
    <Container maxWidth="sm">
      <CssBaseline />

      <CommandInputUI runCommand={runCommand} />
      {/*<CreateCounterFab/>*/}
      {/*/!*<NewCounter />*!/*/}
      {/*<CounterListContainer/>*/}
      {/*{cmdHistory.map((elem) => elem)}*/}
      {cmdHistory}
      {!loading && !error && data && <IPCommandUI ip={data.thisip} />}
    </Container>
  );
};
