import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useIpQuery } from "./commands.gen";
import { CommandCardUI } from "./command-card/command-card-ui";
import { CommandCardLoadingUI } from "./command-card/command-card-loading-ui";

interface Props {
  ip: string;
}

export const IPCommandUI: React.FC<Props> = (props) => {
  const { ip } = props;
  const { data, loading, error } = useIpQuery({ variables: { ip: ip } });
  if (loading) {
    return <CommandCardLoadingUI command={props.ip} count={2} />;
  }
  if (error) {
    return <CommandCardLoadingUI command={props.ip} errors={[error.message]} />;
  }
  return (
    <CommandCardUI command={ip}>
      {data!.ip.map((item) => {
        return (
          <Grid key={item!.id} container>
            <Grid item xs>
              <Typography>{item!.label}</Typography>
            </Grid>
            <Grid item xs>
              <Typography>{item!.value}</Typography>
            </Grid>
          </Grid>
        );
      })}
    </CommandCardUI>
  );
};
